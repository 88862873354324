import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {
  Login,
  Home,
  AddPerson,
  AdminHome,
  Detail,
  Dashboard,
} from "./../../Container/index.js";
export default function BasicRouter() {
  return (
    <Router>
      {/* <Switch> */}
      <Route exact path="/" component={Home} />
      <Route path="/admin" component={Login} />
      <Route path="/adminHome" component={AdminHome} />
      <Route path="/addperson" component={AddPerson} />
      <Route path="/Detail" component={Detail} />
      <Route path="/Dashboard" component={Dashboard} />
    </Router>
  );
}
