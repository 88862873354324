import React, { Component } from "react";
import { GetData } from "./../../Config/Databse/function";
import AppBar from "./../../component/AppBAr/AppBar";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import Card from "./../../component/Card/card";
import { Grid } from "@material-ui/core";
import Loader from "./../../component/Loader/Loader";
import City from "./../../Config/City/city";
import AllYears from "./../../Config/Year/Year";
import TextField from "@material-ui/core/TextField";
import Dropdown from "./../../component/CityDropdown/Citydropdown";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      AllPersons: [],
      city: "",
      lang: "en",
      name: "",
      date: new Date("2014-08-18T21:11:54"),
      Year: "",
    };
  }

  componentDidMount = async () => {
    let { AllPersons } = this.state;
    try {
      let getData = await GetData();
      await getData.forEach((val) => {
        console.log(val.data());
        AllPersons.push(val.data());
      });
      let Lang = await localStorage.getItem("lang");
      await i18next.changeLanguage(Lang);
      console.log(Lang);
      this.setState({
        AllPersons: AllPersons,
        lang: Lang,
      });
    } catch (err) {
      this.setState({
        err: err,
      });
    }
  };

  handleClick = (lang) => {
    i18next.changeLanguage(lang.target.value);
    console.log(lang.target.value);
    this.setState({
      lang: lang.target.value,
    });
    localStorage.setItem("lang", lang.target.value);
  };

  SerchValue = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  SerchCityValue = (e) => {
    this.setState({
      city: e.label,
    });
  };

  path = () => {
    this.setState({
      name: "",
      city: "",
      Year: "",
    });
  };
  render() {
    let { AllPersons, city, Year, name } = this.state;
    const { t } = this.props;
    const filterYear = AllPersons.filter((users) => {
      console.log(users.Year === Year);
      console.log(users.Year, Number(Year));
      return (
        users.city.toLowerCase().includes(city.toLowerCase()) &&
        users.name.toLowerCase().includes(name.toLowerCase())
      );
    });
    const filterPerson = filterYear.filter((users) => {
      return Year ? users.Year === Number(Year) : users;
    });
    console.log(filterPerson);
    return (
      <div>
        <AppBar
          btnValue={{ name: t("nav.home"), path: this.path }}
          btnValue1={{ name: t("nav.addperson"), path: "/adminHome" }}
          btnValue2={{
            name: "Language",
            path: "/adminHome",
            handleClick: this.handleClick,
          }}
          history={this.props.history}
          lang={{
            lang: this.state.lang,
            en: t("lang.en"),
            fr: t("lang.fr"),
            ht: t("lang.ht"),
          }}
        />
        <div style={{ width: "100%", margin: "auto", marginTop: "8vh" }}>
          <Grid
            container
            justify="center"
            style={{
              backgroundColor: "#8c76a5",
            }}
          >
            <div style={{ width: "30" }}></div>
            <Grid item lg={4} xl={4} md={4} sm={4} xs={12}>
              <div
                style={{
                  margin: "auto",
                  textAlign: "center",
                  padding: 20,
                  backgroundColor: "#8c76a5",
                }}
              >
                <Dropdown
                  onChange={this.SerchCityValue}
                  placeholder={t("search.city")}
                />
                {/* <select
                  style={{
                    width: "80%",
                    border: "none",
                    borderBottom: "1px solid #4c3c4c",
                    fontSize: 17,
                    color: "#4c3c4c",
                    fontWeight: "normal",
                    margin: "auto",
                    backgroundColor: "#8c76a5",
                  }}
                  name="city"
                  onChange={(e) => this.SerchValue(e)}
                >
                  <option value="">{t("search.city")}</option>
                  {City.map((val, i) => (
                    <option kry={i} value={val}>
                      {val}
                    </option>
                  ))}
                </select> */}
              </div>
            </Grid>

            <Grid item lg={4} xl={4} md={4} sm={4} xs={12}>
              <div
                style={{
                  margin: "auto",
                  marginTop: "-20px",
                  textAlign: "center",
                  padding: 20,
                }}
              >
                <TextField
                  id="standard-password-input"
                  label={t("search.name")}
                  name="name"
                  type="text"
                  value={name}
                  autoComplete="current-password"
                  style={{ width: "80%" }}
                  floatingLabelStyle={{ fontSize: 20 }}
                  onChange={(e) => this.SerchValue(e)}
                />
              </div>
            </Grid>

            <Grid item lg={4} xl={4} md={4} sm={4} xs={12}>
              <div
                style={{
                  margin: "auto",
                  textAlign: "center",
                  padding: 20,
                }}
              >
                <select
                  value={Year}
                  style={{
                    width: "80%",
                    border: "none",
                    borderBottom: "1px solid #4c3c4c",
                    fontSize: 17,
                    color: "#4c3c4c",
                    margin: "auto",
                    backgroundColor: "#8c76a5",
                  }}
                  name="Year"
                  onChange={(e) => this.SerchValue(e)}
                >
                  <option value="">{t("search.year")}</option>
                  {AllYears.map((val, i) => (
                    <option kry={i} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            </Grid>
          </Grid>
          {AllPersons.length ? (
            <Grid container>
              {filterPerson.length ? (
                filterPerson.map((val, i) => (
                  <>
                    <Grid item lg={3} xl={3} md={4} sm={6} xs={6}>
                      <Card
                        val={val}
                        btn={t("seemore")}
                        ChangeValue={() =>
                          this.props.history.push("Detail", { val })
                        }
                        card={{
                          name: t("contact.name"),
                          age: t("contact.age"),
                          lastSeen: t("contact.lastLocation"),
                          btn: t("notfound"),
                        }}
                      />
                    </Grid>
                  </>
                ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    margin: "auto",
                    marginTop: 250,
                    alignItems: "center",
                  }}
                >
                  <h1 style={{ textAlign: "center", color: "white" }}>
                    Preson Not Nound
                  </h1>
                </div>
              )}
            </Grid>
          ) : (
            <div
              style={{
                width: "10%",
                margin: "auto",
                marginTop: 250,
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Home);
