import React from "react";
import Grid from "@material-ui/core/Grid";
import "./detailCard.css";
import AppBar from "./../../component/AppBAr/AppBar";
import { withTranslation } from "react-i18next";
import i18next from "i18next";

class CardDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      AllPersons: [],
      City: "",
      Year: "",
      lang: "en",
    };
  }

  componentDidMount = async () => {
    try {
      let Lang = await localStorage.getItem("lang");
      await i18next.changeLanguage(Lang);
      this.setState({});
    } catch (err) {
      this.setState({
        err: err,
      });
    }
  };

  handleClick = (lang) => {
    i18next.changeLanguage(lang.target.value);
    this.setState({
      lang: lang.target.value,
    });
    localStorage.setItem("lang", lang.target.value);
  };

  render() {
    let {
      name,
      city,
      contact_No,
      description,
      eyes_color,
      height,
      image,
      lastSeenLocation,
      missingDate,
      age,
    } = this.props.location.state.val;
    const { t } = this.props;

    return (
      <>
        <AppBar
          btnValue={{ name: t("nav.home"), path: "/" }}
          btnValue1={{ name: t("nav.addperson"), path: "/adminHome" }}
          btnValue2={{
            name: "Language",
            path: "/adminHome",
            handleClick: this.handleClick,
          }}
          history={this.props.history}
          lang={{
            lang: this.state.lang,
            en: t("lang.en"),
            fr: t("lang.fr"),
            ht: t("lang.ht"),
          }}
        />
        <div className="_container">
          <Grid container justify="center">
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <img
                  src={image}
                  alt="profile"
                  style={{
                    width: "50%",
                    height: "100%",
                    borderRadius: "10%",
                    border: "3px solid #422a5f",
                    margin: "auto",
                  }}
                />
              </div>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <div>
                <span className="_Name">
                  {t("contact.name")} : {name}
                </span>
                <span className="_Name">
                  {t("contact.age")} : {age} Years
                </span>
                <span className="_Name">
                  {t("contact.contact")} : {contact_No}
                </span>

                <span className="_Name">
                  {t("contact.eyesColor")} : {eyes_color}
                </span>
                <span className="_Name">
                  {t("contact.missingDate")} : {missingDate}
                </span>

                <span className="_Name">
                  {t("contact.city")} : {city}
                </span>
                <span className="_Name">
                  {t("contact.lastLocation")} : {lastSeenLocation}
                </span>
                <span className="_Name">
                  {t("contact.height")} : {height}
                </span>
                <span className="_Name">
                  {t("contact.description")} : {description}
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default withTranslation()(CardDetail);
