import React, { Component } from "react";
import { AddPersonFunc } from "./../../Config/Databse/function";
import { FirebaseApp } from "./../../Config/Databse/firebase";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { TextArea } from "semantic-ui-react";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import City from "./../../Config/City/city";
import AppBar from "./../../component/AppBAr/AppBar";
import swal from "sweetalert";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Dropdown from "./../../component/addcitydropdown/addcitydropdown";

import { withTranslation } from "react-i18next";
import i18next from "i18next";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

class AddPerson extends Component {
  constructor() {
    super();
    this.state = {
      missingDate: new Date(),
      name: "",
      age: "",
      city: "",
      eyes_color: "",
      height: "",
      lastSeenLocation: "",
      image: "",
      contact_No: "",
      description: "",
      Year: "",
      value: "",
      lang: "en",
    };
  }

  imageFunc = async (e) => {
    let imagename = e.target.files[0].name;
    console.log(imagename);
    let ref = FirebaseApp.storage()
      .ref("/")
      .child("image/" + imagename);
    await ref.put(e.target.files[0]);
    ref.getDownloadURL().then((url) =>
      this.setState({
        image: url,
      })
    );
  };
  // Get Input value Function

  GataInputValue = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  GataCityValue = (e) => {
    this.setState({
      city: e.label,
    });
  };

  MobileInput = (e) => {
    let num = e.target.value;
    console.log(num.length);

    if (!isNaN(num) && this.state.contact_No.length <= 19) {
      var cleaned = ("" + num).replace(/\D/g, "");
      var match = cleaned.match(/^(509|)?(\d{3})(\d{2})(\d{2})(\d{4})$/);
      if (match) {
        console.log("Match", match[1]);

        let number = [
          "(",
          match[2],
          ") ",
          match[3],
          "-",
          match[4],
          "-",
          match[5],
        ].join("");
        console.log(number);
        this.setState({
          contact_No: number,
        });
        return;
      }
      this.setState({
        contact_No: num,
      });
    } else {
      this.setState({
        contact_No: num,
      });
    }
  };

  // missing Date Function
  handleDatemissingChange = (date) => {
    if (isNaN(date.getDate())) {
      this.setState({ misDatae: new Date() });
    } else {
      let dateofmissing =
        date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
      this.setState({
        missingDate: dateofmissing,
        misDatae: date,
        Year: date.getFullYear(),
      });
    }
  };

  handleClick = (lang) => {
    i18next.changeLanguage(lang.target.value);
    this.setState({
      lang: lang.target.value,
    });
    localStorage.setItem("lang", lang.target.value);
  };

  componentDidMount = async () => {
    let Lang = await localStorage.getItem("lang");
    await i18next.changeLanguage(Lang);
    this.setState({});
  };

  AddData = async () => {
    let {
      name,
      missingDate,
      age,
      city,
      eyes_color,
      height,
      lastSeenLocation,
      image,
      contact_No,
      description,
      Year,
    } = this.state;

    try {
      if (
        name &&
        missingDate &&
        age &&
        city &&
        eyes_color &&
        height &&
        lastSeenLocation &&
        image &&
        contact_No &&
        description &&
        Year
      ) {
        await AddPersonFunc({
          name,
          missingDate,
          age,
          city,
          eyes_color,
          height,
          lastSeenLocation,
          image,
          contact_No,
          description,
          Year,
          approve: false,
          found: false,
        });
        await swal(this.props.t("warn.success"), "", "success");
        await this.setState({
          name: "",
          age: "",
          city: "",
          eyes_color: "",
          height: "",
          lastSeenLocation: "",
          image: "",
          contact_No: "",
          description: "",
          Year: "",
        });
      } else {
        await swal(this.props.t("warn.allData"), "", "error");
      }
    } catch (err) {
      await swal(this.props.t("warn.internet"), "", "error");
    }
  };

  path = () => {
    this.props.history.push("/");
  };
  render() {
    let {
      date,
      misDatae,
      name,
      age,
      city,
      eyes_color,
      height,
      lastSeenLocation,
      image,
      contact_No,
      description,
    } = this.state;
    console.log(this.state);
    const { t } = this.props;
    return (
      <div>
        <AppBar
          btnValue={{ name: t("nav.home"), path: this.path }}
          btnValue1={{ name: t("nav.addperson"), path: "/adminHome" }}
          btnValue2={{
            name: "Language",
            path: "/adminHome",
            handleClick: this.handleClick,
          }}
          history={this.props.history}
          lang={{
            lang: this.state.lang,
            en: t("lang.en"),
            fr: t("lang.fr"),
            ht: t("lang.ht"),
          }}
        />
        <Grid container justify="center" style={{ marginTop: "12vh" }}>
          <Grid
            item
            xs={11}
            sm={10}
            md={6}
            lg={4}
            style={{ textAlign: "center" }}
          >
            <Paper style={{ padding: 20 }}>
              <TextField
                id="standard-password-input"
                label={t("contact.name")}
                type="text"
                name="name"
                value={name}
                autoComplete="current-password"
                style={{ width: "90%" }}
                onChange={(e) => this.GataInputValue(e)}
              />

              <TextField
                id="standard-password-input"
                label={t("contact.age")}
                name="age"
                type="number"
                value={age}
                autoComplete="current-password"
                style={{ width: "90%" }}
                onChange={(e) => this.GataInputValue(e)}
              />

              <div
                style={{
                  width: "90%",
                  border: "none",
                  // marginTop: "5%",
                  fontSize: 17,
                  color: "gray",
                  margin: "auto",
                  marginTop: "5%",
                }}
              >
                <Dropdown
                  onChange={this.GataCityValue}
                  placeholder={t("search.city")}
                />
              </div>

              <TextField
                id="standard-password-input"
                label={t("contact.height")}
                name="height"
                type="text"
                value={height}
                autoComplete="current-password"
                style={{ width: "90%" }}
                onChange={(e) => this.GataInputValue(e)}
              />
              <TextField
                id="standard-password-input"
                label={t("contact.eyesColor")}
                type="text"
                name="eyes_color"
                value={eyes_color}
                autoComplete="current-password"
                style={{ width: "90%" }}
                onChange={(e) => this.GataInputValue(e)}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ width: "90%", marginLeft: 4 }}
                  margin="normal"
                  id="date-picker-dialog"
                  label={t("contact.missingDate")}
                  format="MM/dd/yyyy"
                  value={misDatae}
                  onChange={(e) => this.handleDatemissingChange(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  maxDate={new Date()}
                />
              </MuiPickersUtilsProvider>
              <TextField
                id="standard-password-input"
                label={t("contact.lastLocation")}
                name="lastSeenLocation"
                type="text"
                autoComplete="current-password"
                style={{ width: "90%" }}
                value={lastSeenLocation}
                onChange={(e) => this.GataInputValue(e)}
              />
              <br />
              <br />

              <TextField
                id="standard-password-input"
                type="file"
                autoComplete="current-password"
                style={{ width: "90%" }}
                name={image}
                onChange={(e) => this.imageFunc(e)}
              />
              <br />

              <TextField
                id="standard-password-input"
                label={t("contact.contact")}
                type="text"
                name="contact_No"
                value={contact_No}
                placeholder="(509) XX-XX-XXXX"
                autoComplete="current-password"
                style={{ width: "90%" }}
                onChange={(e) => this.MobileInput(e)}
              />

              {/* <PhoneInput
                placeholder="Enter phone number"
                value={this.state.value}
                onChange={(e) => console.log(e)}
              /> */}
              <br />
              <div
                style={{
                  width: "90%",
                  margin: "auto",
                }}
              >
                {/* <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="HT"
                  value={contact_No}
                  onChange={(e) => this.setState({ contact_No: e })}
                /> */}
              </div>
              <br />
              <br />
              <TextArea
                id="standard-password-input"
                placeholder={t("contact.description")}
                type="number"
                name="description"
                value={description}
                autoComplete="current-password"
                style={{ width: "90%" }}
                onChange={(e) => this.GataInputValue(e)}
              />
              <Button
                style={{
                  width: "50%",
                  backgroundColor: "blue",
                  color: "white",
                }}
                onClick={() => this.AddData()}
              >
                {t("contact.button")}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(AddPerson);
