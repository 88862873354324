import React from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AppBar from "./../../../component/AdminAppbar/AddminAppbar";
import swal from "sweetalert";
import { FirebaseApp } from "./../../../Config/Databse/firebase";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
class AdminLogin extends React.Component {
  constructor() {
    super();
    this.state = {
      lang: "en",
    };
  }

  componentDidMount = async () => {
    FirebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        this.props.history.push("/adminHome");
      }
    });
    let Lang = await localStorage.getItem("lang");
    console.log(Lang);
    await i18next.changeLanguage(Lang);
    this.setState({ lang: Lang });
  };

  detDataValue = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  loginFunc = async () => {
    let { Email, Password } = this.state;
    if (!Email && !Password) {
      swal("Please Fill both  Email and Password", "", "error");
    } else {
      FirebaseApp.auth()
        .signInWithEmailAndPassword(Email, Password)
        .then((user) => {
          console.log(user);
          localStorage.setItem("login", true);
          swal("Login Succeessfully successfully", "", "success");
          this.props.history.push("/adminHome");
        })
        .catch(() => {
          console.log("error");
          swal("Please enter right Email and Password", "", "error");
        });
    }
  };
  handleClick = (lang) => {
    i18next.changeLanguage(lang.target.value);
    this.setState({
      lang: lang.target.value,
    });
    localStorage.setItem("lang", lang.target.value);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <AppBar
          btnValue={{ name: "", path: "" }}
          btnValue1={{ name: "", path: "" }}
          dashboard={{ name: "", path: "" }}
          btnValue2={this.handleClick}
          history={this.props.history}
          lang={{
            lang: this.state.lang,
            en: t("lang.en"),
            fr: t("lang.fr"),
            ht: t("lang.ht"),
          }}
        />
        <br />
        <br />
        <br />

        <Grid container justify="center" style={{ textAlign: "center" }}>
          <Grid item xs={11} sm={10} md={6} lg={4}>
            <Paper style={{ padding: 30 }}>
              <h1>{t("login.title")}</h1>
              <TextField
                required
                id="standard-required"
                label={t("login.email")}
                name="Email"
                onChange={(e) => this.detDataValue(e)}
                style={{ width: "100%" }}
              />
              <br />
              <br />
              <TextField
                style={{ width: "100%" }}
                type="password"
                required
                id="standard-required"
                name="Password"
                onChange={(e) => this.detDataValue(e)}
                label={t("login.password")}
              />
              <br />
              <br />
              <Button
                onClick={() => this.loginFunc()}
                style={{
                  backgroundColor: "blue",
                  width: "44%",
                  color: "white",
                }}
              >
                {t("login.btn")}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(AdminLogin);
