import React from "react";
class AdminLogin extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>
        <h1 style={{textAlign : "center" ,marginTop: "25%"}}>Page Not Found</h1>
      </div>
    );
  }
}

export default AdminLogin;
