function Years() {
  let startYear = 1980;
  var currentYear = new Date().getFullYear();
  var years = [];
  for (var i = startYear; i <= currentYear; i++) {
    years.push(i);
  }
  return years.reverse();
}
let AllYears = Years();
export default AllYears;
