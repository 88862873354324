import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "./../Card/card";
import FoundCard from "./../FoundCard/FoundCard";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "#422a5f",
          boxShadow: "4px 4px 4px 2px rgb(224, 218, 218)",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label={props.panding} {...a11yProps(0)} />
          <Tab label={props.approved} {...a11yProps(1)} />
          <Tab label={props.founded} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container>
          {props.pandingData.length ? (
            props.pandingData.map((val, i) => {
              return (
                <Grid item lg={3} xl={3} md={6} sm={6} xs={12}>
                  <Card
                    val={val}
                    btn={props.paddingBtn}
                    ChangeValue={props.ApproveFunc}
                    card={props.card}
                  />
                </Grid>
              );
            })
          ) : (
            <div
              style={{
                alignContent: "center",
                backgroundColor: "black",
                margin: "auto",
                color: "white",
                padding: 20,
              }}
            >
              <h1>{props.card.btn}</h1>
            </div>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container>
          {props.approveData.length ? (
            props.approveData.map((val, i) => {
              return (
                <Grid item lg={3} xl={3} md={6} sm={6} xs={12}>
                  <Card
                    val={val}
                    btn={props.approveBtn}
                    ChangeValue={props.FoundFunc}
                    card={props.card}
                  />
                </Grid>
              );
            })
          ) : (
            <div
              style={{
                alignContent: "center",
                backgroundColor: "black",
                margin: "auto",
                color: "white",
                padding: 20,
              }}
            >
              <h1>{props.card.btn}</h1>
            </div>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container>
          {props.foundData.length ? (
            props.foundData.map((val, i) => {
              return (
                <Grid item lg={3} xl={3} md={6} sm={6} xs={12}>
                  <FoundCard
                    val={val}
                    btn={props.FoundBtn}
                    card={props.card}
                    ChangeValue={props.backToLost}
                    delete={props.Delete}
                    deletebtn={props.deletebtn}
                  />
                </Grid>
              );
            })
          ) : (
            <div
              style={{
                alignContent: "center",
                backgroundColor: "black",
                margin: "auto",
                color: "white",
                padding: 20,
              }}
            >
              <h1>{props.card.btn}</h1>
            </div>
          )}
        </Grid>
      </TabPanel>
    </div>
  );
}
