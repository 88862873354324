import { FirebaseApp } from "./firebase";
let AddPersonFunc = (state) => {
  FirebaseApp.firestore()
    .collection("MissingPerson")
    .add(state)
    .then(() => {
      console.log("Add successfully");
    })
    .catch((err) => console.log(err));
};

let GetAllData = async () => {
  return new Promise((resolve, reject) => {
    FirebaseApp.firestore()
      .collection("MissingPerson")
      .get()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

let GetData = async () => {
  return new Promise((resolve, reject) => {
    FirebaseApp.firestore()
      .collection("MissingPerson")
      .where("approve", "==", true)
      .where("found", "==", false)

      .get()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

let Pandding = async () => {
  return new Promise((resolve, reject) => {
    FirebaseApp.firestore()
      .collection("MissingPerson")
      .where("approve", "==", false)
      .get()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

let Approve = async () => {
  return new Promise((resolve, reject) => {
    FirebaseApp.firestore()
      .collection("MissingPerson")
      .where("approve", "==", true)
      .where("found", "==", false)
      .get()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

let Found = async () => {
  return new Promise((resolve, reject) => {
    FirebaseApp.firestore()
      .collection("MissingPerson")
      .where("found", "==", true)
      .get()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

let Approveperson = async (val) => {
  return new Promise((resolve, reject) => {
    FirebaseApp.firestore()
      .collection("MissingPerson")
      .doc(val)
      .update({ approve: true })
      .then(() => {
        resolve("success");
      })
      .catch((err) => reject(err));
  });
};

let FoundPerson = async (val) => {
  return new Promise((resolve, reject) => {
    FirebaseApp.firestore()
      .collection("MissingPerson")
      .doc(val)
      .update({ found: true })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

let backToLostPerson = async (val) => {
  return new Promise((resolve, reject) => {
    FirebaseApp.firestore()
      .collection("MissingPerson")
      .doc(val)
      .update({ found: false })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

let DeletePerson = async (val) => {
  return new Promise((resolve, reject) => {
    FirebaseApp.firestore()
      .collection("MissingPerson")
      .doc(val)
      .delete()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export {
  AddPersonFunc,
  GetData,
  Pandding,
  Approve,
  Found,
  Approveperson,
  FoundPerson,
  GetAllData,
  backToLostPerson,
  DeletePerson,
};
