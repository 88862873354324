import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#8c76a5",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();
  console.log(props.history);
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        style={{ backgroundColor: "#422a5f", color: "#d5ddde" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          ></IconButton>
          <Typography variant="h6" className={classes.title}>
            {props.btnValue.name}
          </Typography>
          <Button color="inherit" onClick={() => props.btnValue1.logout()}>
            {props.btnValue1.name}
          </Button>
          <Button
            color="inherit"
            onClick={() => props.dashboard.click(props.dashboard.path)}
          >
            {props.dashboard.name}
          </Button>

          <select
            style={{
              backgroundColor: "#422a5f",
              color: "#d5ddde",
              border: "none",
              width: "100px",
            }}
            onChange={(e) => props.btnValue2(e)}
            value={props.lang.lang}
          >
            <option
              value="en"
              style={{ width: "200px", height: " 200px", color: "white" }}
            >
              {props.lang.en}
            </option>
            <option value="fr"> {props.lang.fr}</option>

            <option value="ht">{props.lang.ht}</option>
          </select>
        </Toolbar>
      </AppBar>
    </div>
  );
}
