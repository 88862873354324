import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import "./Appbar.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#8c76a5",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar(props) {
  const [selectedValue, setSelectedValue] = useState("en");

  useEffect(() => {
    let lag = localStorage.getItem("lang");
    console.log(lag);
    setSelectedValue(lag ? lag : "en");
  }, []);
  const classes = useStyles();
  console.log(props.history.goBack);
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "#422a5f",
          color: "#d5ddde",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            {/* <span className="hide">BackButton</span> */}
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {props.navTitle}
          </Typography>
          <Button color="inherit" onClick={() => props.btnValue.path()}>
            {props.btnValue.name}
          </Button>
          <Button
            color="inherit"
            onClick={() => props.history.push("/addperson")}
          >
            {props.btnValue1.name}
          </Button>
          <select
            style={{
              backgroundColor: "#422a5f",
              color: "#d5ddde",
              borderColor: "£422a5f",
              width: "80px",
            }}
            value={selectedValue}
            onChange={(e) => {
              console.log(e.target.value);
              setSelectedValue(e.target.value);
              props.btnValue2.handleClick(e);
            }}
          >
            <option
              value="en"
              style={{ width: "200px", height: " 200px", color: "white" }}
            >
              {props.lang.en}
            </option>
            <option value="fr">{props.lang.fr}</option>
            <option value="ht">{props.lang.ht}</option>
          </select>
        </Toolbar>
      </AppBar>
    </div>
  );
}
