import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./dashboardCard.css";

export default function OutlinedCard(props) {
  return (
    <div className="_cardDi">
      <p className="title">{props.name}</p>
      <p className="amount"> {props.value}</p>
    </div>
  );
}
