import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./FoundCard.css";

const useStyles = makeStyles({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  cardText: {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: 0.8,
    fontFamily: "system-ui",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  spanText: {
    fontWeight: "normal",
  },
});

export default function OutlinedCard(props) {
  const classes = useStyles();
  console.log(props);
  return (
    // <Card className={classes.root} >
    <div className="_cardDiv1">
      <img
        alt="profile"
        src={props.val.image}
        style={{
          border: "1px solid black",
          borderRadius: "50%",
          width: 150,
          height: 150,
        }}
      />
      <div>
        <p className={classes.cardText}>
          {props.card.name}
          <span className={classes.spanText}> : {props.val.name}</span>
        </p>
        <p className={classes.cardText}>
          {props.card.age}
          <span className={classes.spanText}> : {props.val.age}</span>
        </p>
        <p className={classes.cardText}>
          {props.card.lastSeen}
          <span className={classes.spanText}>
            {" "}
            : {props.val.lastSeenLocation}
          </span>
        </p>
        {/* <p className={classes.cardText}>
          {props.card.contact} :
          <span className={classes.spanText}> {props.val.contact_No}</span>
        </p> */}
        <div className="btnDiv">
          <button
            className="backtolost"
            onClick={() => props.ChangeValue(props.val.id)}
          >
            {props.btn}
          </button>

          <button
            className="deletebtn"
            onClick={() => props.deletebtn(props.val.id)}
          >
            {props.delete}
          </button>
        </div>
      </div>
    </div>
  );
}
