import React from "react";
import Select from "react-select";

const scaryAnimals = [
  {
    value: 1,
    label: "Abricots",
  },

  {
    value: 2,
    label: "Acul du Nord",
  },

  {
    value: 3,
    label: "Anse d'Hainault",
  },
  {
    value: 4,
    label: "Anse-à-Foleur",
  },
  {
    value: 5,
    label: "Anse-à-Galets",
  },
  {
    value: 6,
    label: "Anse-à-Pitres",
  },
  {
    value: 7,
    label: "Anse-à-Veau",
  },
  {
    value: 8,
    label: "Anse-d'Hainault",
  },
  {
    value: 9,
    label: "Anse-Rouge",
  },
  {
    value: 10,
    label: "Aquin",
  },
  {
    value: 11,
    label: "Arcahaie",
  },
  {
    value: 12,
    label: "Arnaud",
  },
  {
    value: 13,
    label: "Arniquet",
  },
  {
    value: 14,
    label: "Bahon",
  },
  {
    value: 15,
    label: "Baie-de-Henne",
  },
  {
    value: 16,
    label: "Bainet",
  },
  {
    value: 17,
    label: "Baradères",
  },
  {
    value: 18,
    label: "Bas-Limbé",
  },
  {
    value: 19,
    label: "Bassin-Bleu",
  },
  {
    value: 20,
    label: "Beaumont",
  },
  {
    value: 21,
    label: "Belladère",
  },
  {
    value: 22,
    label: "Belle-Anse",
  },
  {
    value: 23,
    label: "Bombardopolis",
  },
  {
    value: 24,
    label: "Bonbon",
  },
  {
    value: 25,
    label: "Borgne",
  },
  {
    value: 26,
    label: "Boucan-Carré",
  },
  {
    value: 27,
    label: "Cabaret",
  },
  {
    value: 28,
    label: "Camp-Perrin",
  },
  {
    value: 29,
    label: "Cap-Haïtien",
  },
  {
    value: 30,
    label: "Capotille",
  },
  {
    value: 31,
    label: "Caracol",
  },
  {
    value: 32,
    label: "Carice",
  },
  {
    value: 33,
    label: "Carrefour",
  },
  {
    value: 34,
    label: "Cavaellon",
  },
  {
    value: 35,
    label: "Cayes-Jacmel",
  },
  {
    value: 36,
    label: "Cerca-Carvajal",
  },
  {
    value: 37,
    label: "Cerca-la-Source",
  },
  {
    value: 38,
    label: "Chambellan",
  },
  {
    value: 39,
    label: "Chansolme",
  },
  {
    value: 40,
    label: "Chantal",
  },
  {
    value: 41,
    label: "Chardonnières",
  },
  {
    value: 42,
    label: "Cité Soleil",
  },
  {
    value: 43,
    label: "Corail",
  },
  {
    value: 44,
    label: "Cornillon",
  },
  {
    value: 45,
    label: "Côteaux",
  },
  {
    value: 46,
    label: "Côtes-de-Fer",
  },
  {
    value: 47,
    label: "Croix-des-Bouquets",
  },
  {
    value: 48,
    label: "Dame Marie",
  },
  {
    value: 49,
    label: "Delmas",
  },
  {
    value: 50,
    label: "Desdunes",
  },
  {
    value: 51,
    label: "Dessalines",
  },
  {
    value: 52,
    label: "Dondon",
  },
  {
    value: 53,
    label: "Ennery",
  },
  {
    value: 54,
    label: "Ferrier",
  },
  {
    value: 55,
    label: "Fond-des-Blancs",
  },
  {
    value: 56,
    label: "Fonds-des-Nègres",
  },
  {
    value: 57,
    label: "Fonds-Verrettes",
  },
  {
    value: 57,
    label: "Fort-Liberté",
  },
  {
    value: 58,
    label: "Ganthier",
  },
  {
    value: 59,
    label: "Gonaïves",
  },
  {
    value: 60,
    label: "Grand-Boucan",
  },
  {
    value: 61,
    label: "Grande-Rivière-du-Nord",
  },
  {
    value: 62,
    label: "Grande-Saline",
  },
  {
    value: 63,
    label: "Grand-Goâve",
  },
  {
    value: 64,
    label: "Grand-Gosier",
  },
  {
    value: 65,
    label: "Gressier",
  },
  {
    value: 66,
    label: "Gros-Morne",
  },
  {
    value: 67,
    label: "Hinche",
  },
  {
    value: 68,
    label: "Île de la Tortue",
  },
  {
    value: 69,
    label: "Île-à-Vache",
  },
  {
    value: 70,
    label: "Jacmel",
  },
  {
    value: 71,
    label: "Jean-Rabel",
  },
  {
    value: 72,
    label: "Jérémie",
  },
  {
    value: 73,
    label: "Kenscoff",
  },
  {
    value: 74,
    label: "La Chapelle",
  },
  {
    value: 75,
    label: "La Vallée-de-Jacmel",
  },
  {
    value: 76,
    label: "La Victoire",
  },
  {
    value: 77,
    label: "Lascahobas",
  },
  {
    value: 78,
    label: "L'Asile",
  },
  {
    value: 79,
    label: "Léogâne",
  },
  {
    value: 80,
    label: "Les Anglais",
  },
  {
    value: 81,
    label: "Les Cayes",
  },
  {
    value: 82,
    label: "Les Irois",
  },
  {
    value: 83,
    label: "L'Estère",
  },
  {
    value: 84,
    label: "Limbé",
  },
  {
    value: 85,
    label: "Limonade",
  },
  {
    value: 86,
    label: "Maïssade",
  },
  {
    value: 87,
    label: "Maniche",
  },
  {
    value: 88,
    label: "Marigot",
  },
  {
    value: 89,
    label: "Marmelade",
  },
  {
    value: 90,
    label: "Milot",
  },
  {
    value: 91,
    label: "Miragoâne",
  },
  {
    value: 92,
    label: "Mirebalais",
  },
  {
    value: 93,
    label: "Môle-Saint-Nicolas",
  },
  {
    value: 94,
    label: "Mombin-Crochu",
  },
  {
    value: 95,
    label: "Mont-Organisé",
  },
  {
    value: 96,
    label: "Moron",
  },
  {
    value: 97,
    label: "Ouanaminthe",
  },
  {
    value: 98,
    label: "Paillant",
  },
  {
    value: 99,
    label: "Perches",
  },
  {
    value: 100,
    label: "Pestel",
  },
  {
    value: 101,
    label: "Pétion-Ville",
  },
  {
    value: 102,
    label: "Petit Troude Nippes",
  },
  {
    value: 103,
    label: "Petite Rivière de l'Artibonite",
  },
  {
    value: 104,
    label: "Petite-Rivière-de-Nippes",
  },
  {
    value: 104,
    label: "Petit-Goâve",
  },
  {
    value: 105,
    label: "Pignon",
  },
  {
    value: 106,
    label: "Pilate",
  },
  {
    value: 107,
    label: "Plaine du Nord",
  },
  {
    value: 108,
    label: "Plaisance",
  },
  {
    value: 109,
    label: "Plaisance-du-Sud",
  },
  {
    value: 110,
    label: "Pointe-à-Raquette",
  },
  {
    value: 111,
    label: "Port-à-Piment",
  },
  {
    value: 112,
    label: "Port-au-Prince",
  },
  {
    value: 113,
    label: "Port-de-Paix",
  },
  {
    value: 114,
    label: "Port-Margot",
  },
  {
    value: 115,
    label: "Port-Salut",
  },
  {
    value: 116,
    label: "Quartier-Morin",
  },
  {
    value: 117,
    label: "Ranquitte",
  },
  {
    value: 118,
    label: "Roche-à-Bateaux",
  },
  {
    value: 119,
    label: "Roseaux",
  },
  {
    value: 120,
    label: "Sainte-Suzanne",
  },
  {
    value: 121,
    label: "Saint-Jean-du-Sud",
  },
  {
    value: 122,
    label: "Saint-Louis-du-Nord",
  },
  {
    value: 123,
    label: "Saint-Louis-du-Sud",
  },
  {
    value: 124,
    label: "Saint-Marc",
  },
  {
    value: 125,
    label: "Saint-Michel-de-l'Atalaye",
  },
  {
    value: 126,
    label: "Saint-Raphaël",
  },
  {
    value: 127,
    label: "Saut-d'Eau",
  },
  {
    value: 128,
    label: "Savanette",
  },
  {
    value: 129,
    label: "Tabarre",
  },
  {
    value: 130,
    label: "Terre-Neuve",
  },
  {
    value: 131,
    label: "Terrier-Rouge",
  },
  {
    value: 132,
    label: "Thiotte",
  },
  {
    value: 133,
    label: "Thomassique",
  },
  {
    value: 134,
    label: "Thomazeau",
  },
  {
    value: 135,
    label: "Thomonde",
  },
  {
    value: 136,
    label: "Torbeck",
  },
  {
    value: 137,
    label: "Trou-du-Nord",
  },
  {
    value: 138,
    label: "Vallières",
  },
  {
    value: 139,
    label: "Verrettes",
  },
];

const customStyles = {
  control: (base, state) => ({
    ...base,

    width: "80%",
    margin: "auto",
    border: "none",
    borderBottom: "1px solid #4c3c4c",
    fontSize: 17,
    color: "#4c3c4c",
    fontWeight: "normal",
    backgroundColor: "#8c76a5",

    // match with the menu
    // Overwrittes the different states of border
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderBottom: "1px solid #4c3c4c",
      color: "black",
      width: "80%",
      borderBottom: "1px solid #4c3c4c",
      backgroundColor: "#8c76a5",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    backgroundColor: "#8c76a5",

    width: "80%",
    margin: "auto",
    alightItem: "center",
    alignSelf: "center",
    marginLeft: "10%",
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option

    width: "100%",
    margin: "auto",
    padding: 0,
    color: "black",
    maginLeft: "300px",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: 17,
      color: "#4c3c4c",
      fontWeight: "normal",
    };
  },
};
const Dropdown = (props) => (
  <Select
    options={scaryAnimals}
    onChange={(text) => props.onChange(text)}
    placeholder={props.placeholder}
    color="black"
    styles={customStyles}
  />
);

export default Dropdown;
