import React from "react";
import AppBar from "./../../../component/AdminAppbar/AddminAppbar";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import Chart from "../../../component/dashboardCard/dashboardCard";
import swal from "sweetalert";
import DetailCard from "./../../../component/dashboardCard/dashboardCard";
import { GetAllData } from "./../../../Config/Databse/function";
import { Grid } from "@material-ui/core";
import { FirebaseApp } from "./../../../Config/Databse/firebase";
import { ExportCSV } from "./../../../component/download/download";

import jsPDF from "jspdf";
class AllDetail extends React.Component {
  constructor(props) {
    super();
    this.state = {
      Allpersons: [],
      lang: "en",
      pandingData: 0,
      approveData: 0,
      foundData: 0,
      download: [],
    };
  }

  componentDidMount = async () => {
    let { Allpersons, download } = this.state;
    let Login = localStorage.getItem("login");
    if (!Login) {
      this.props.history.push("/admin");
    }
    let AllData = await GetAllData();
    await AllData.forEach((val) => {
      let Allvalue = val.data();
      Allvalue.id = val.id;
      Allpersons.push(Allvalue);
      download.push({ Name: Allvalue.name, Contact_No: Allvalue.contact_No });
    });
    this.setState({ Allpersons });

    let data = localStorage.getItem("data");
    if (data) {
      data = JSON.parse(data);
      this.setState({
        pandingData: data.pandingData.length,
        approveData: data.approveData.length,
        foundData: data.foundData.length,
      });
    }
  };
  // jspdfGenerator = () => {
  //   let { Allpersons } = this.state;
  //   let value = 40;
  //   let height = 840;

  //   var doc = new jsPDF("p", "pt");
  //   doc.setTextColor("red");
  //   doc.setFont("arial");
  //   doc.setFontSize(25);
  //   doc.setFontType("bold");
  //   doc.text(100, 20, "All Missing Person Mobile Number");
  //   doc.setTextColor("black");

  //   for (var i = 0; i < Allpersons.length; i++) {
  //     if (value < height) {
  //       doc.setFont("times new roman");
  //       doc.setFontSize(15);
  //       doc.setTableHeaderRow();

  //       doc.setFontType("normal");
  //       doc.text(60, value, "" + (i + 1));
  //       doc.text(140, value, Allpersons[i].name);

  //       doc.text(340, value, Allpersons[i].contact_No);
  //       value = value + 20;
  //     }
  //     if (value >= height) {
  //       value = 20;
  //       doc.addPage();
  //       doc.text(60, value, "" + (i + 1));
  //       doc.text(140, value, Allpersons[i].name);
  //       doc.setFont("courier");
  //       doc.setFontType("bold");
  //       doc.text(340, value, Allpersons[i].contact_No);
  //       value = value + 20;
  //     }
  //   }
  //   doc.save("mobileNumbers.pdf");
  // };
  Logout = () => {
    FirebaseApp.auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        swal("Logout Successfully", "", "success");
        localStorage.removeItem("login");
        this.props.history.push("admin");
      })
      .catch((error) => {
        swal("Some thing is wrong", "", "error");

        // An error happened.
      });
  };

  handleClick = (lang) => {
    i18next.changeLanguage(lang.target.value);
    this.setState({
      lang: lang.target.value,
    });
    localStorage.setItem("lang", lang.target.value);
  };
  Changepath = (path) => {
    this.props.history.push(path);
  };
  render() {
    let { pandingData, approveData, foundData, download } = this.state;

    let { t } = this.props;

    return (
      <div
        style={{
          backgroundImage: require("./../../../Images/HaitiColorsmall.jpg"),
        }}
      >
        <AppBar
          btnValue={{ name: t("dashboard.title"), path: "" }}
          dashboard={{
            name: t("nav.home"),
            path: "adminHome",
            click: this.Changepath,
          }}
          btnValue1={{
            name: t("logout"),
            path: "",
            logout: this.Logout,
          }}
          btnValue2={this.handleClick}
          history={this.props.history}
          lang={{
            lang: this.state.lang,
            en: t("lang.en"),
            fr: t("lang.fr"),
            ht: t("lang.ht"),
          }}
        />
        <Grid container justify="center" style={{ marginTop: "5%" }}>
          <div style={{ width: "30" }}></div>
          <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
            <DetailCard name={t("allDetail.pending")} value={pandingData} />
          </Grid>
          <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
            <DetailCard name={t("allDetail.approved")} value={approveData} />
          </Grid>
          <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
            <DetailCard name={t("allDetail.found")} value={foundData} />
          </Grid>
        </Grid>
        <div
          style={{
            backgroundColor: "none",
            marginTop: "10%",
            marginLeft: "30%",
          }}
        >
         

          <ExportCSV csvData={download} fileName="contact" />
        </div>
      </div>
    );
  }
}
export default withTranslation()(AllDetail);
