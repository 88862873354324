import React from "react";
import AppBar from "./../../../component/AdminAppbar/AddminAppbar";
import Tabs from "./../../../component/Tabs/tabs";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { FirebaseApp } from "./../../../Config/Databse/firebase";
import swal from "sweetalert";

import {
  Pandding,
  Approve,
  Found,
  Approveperson,
  FoundPerson,
  backToLostPerson,
  DeletePerson,
} from "./../../../Config/Databse/function";
class AdminHome extends React.Component {
  constructor(props) {
    super();
    this.state = {
      pandingData: [],
      approveData: [],
      foundData: [],
      lang: "en",
    };
  }

  componentDidMount = async () => {
    let { pandingData, approveData, foundData } = this.state;
    try {
      FirebaseApp.auth().onAuthStateChanged((user) => {
        if (!user) {
          this.props.history.push("/admin");
        }
      });

      let Lang = await localStorage.getItem("lang");
      await i18next.changeLanguage(Lang);
      this.setState({ lang: Lang });
      let pandding = await Pandding();
      await pandding.forEach((val) => {
        let panvalue = val.data();
        panvalue.id = val.id;
        pandingData.push(panvalue);
      });

      let approve = await Approve();
      await approve.forEach((val) => {
        let approvevalue = val.data();
        console.log(approvevalue);
        approvevalue.id = val.id;
        approveData.push(approvevalue);
      });

      let found = await Found();
      await found.forEach((val) => {
        let foundvalue = val.data();
        foundvalue.id = val.id;
        foundData.push(foundvalue);
      });

      this.setState({
        pandingData,
        approveData,
        foundData,
      });

      localStorage.setItem(
        "data",
        JSON.stringify({ pandingData, approveData, foundData })
      );
    } catch (err) {
      this.setState({
        err: err,
      });
    }
  };

  ApproveFunc = async (val) => {
    try {
      await Approveperson(val);
      swal("This Person Added to Approve", "", "success");
    } catch (err) {}
  };

  FoundFunc = async (val) => {
    try {
      await FoundPerson(val);
      swal("This Person Added to Found", "", "success");
    } catch (err) {}
  };

  backToLost = async (val) => {
    try {
      await backToLostPerson(val);
      swal("This Person Added to back to lost", "", "success");
    } catch (err) {}
  };

  deletebtn = async (val) => {
    try {
      await DeletePerson(val);
      swal("This Person is Removed", "", "success");
    } catch (err) {}
  };

  Logout = () => {
    FirebaseApp.auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        localStorage.removeItem("login");
        this.props.history.push("admin");
        swal("Logout Successfully", "", "success");
      })
      .catch(function (error) {
        swal("Some thing is wrong", "", "error");

        // An error happened.
      });
  };

  handleClick = (lang) => {
    i18next.changeLanguage(lang.target.value);
    this.setState({
      lang: lang.target.value,
    });
    localStorage.setItem("lang", lang.target.value);
  };
  Changepath = (path) => {
    this.props.history.push(path);
  };
  render() {
    let { pandingData, approveData, foundData } = this.state;

    let { t } = this.props;

    return (
      <div
        style={{
          backgroundImage: require("./../../../Images/HaitiColorsmall.jpg"),
        }}
      >
        <AppBar
          btnValue={{ name: t("dashboard.title"), path: "" }}
          dashboard={{
            name: t("nav.dashboard"),
            path: "Dashboard",
            click: this.Changepath,
          }}
          btnValue1={{
            name: t("logout"),
            path: "",
            logout: this.Logout,
          }}
          btnValue2={this.handleClick}
          history={this.props.history}
          lang={{
            lang: this.state.lang,
            en: t("lang.en"),
            fr: t("lang.fr"),
            ht: t("lang.ht"),
          }}
        />
        {/* <AppBar btnValue="Logout" /> */}
        <div style={{ marginTop: "8vh" }}>
          <Tabs
            pandingData={pandingData}
            approveData={approveData}
            foundData={foundData}
            paddingBtn={t("btn.panding")}
            approveBtn={t("btn.aprove")}
            FoundBtn={t("btn.found")}
            Delete={t("btn.delete")}
            ApproveFunc={this.ApproveFunc}
            FoundFunc={this.FoundFunc}
            backToLost={this.backToLost}
            deletebtn={this.deletebtn}
            panding={t("tab.panding")}
            approved={t("tab.approved")}
            founded={t("tab.founded")}
            card={{
              name: t("contact.name"),
              age: t("contact.age"),
              lastSeen: t("contact.lastLocation"),
              btn: t("notfound"),
            }}
          />
        </div>
      </div>
    );
  }
}
export default withTranslation()(AdminHome);
